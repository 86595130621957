import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { Absence, User, Holiday } from '../../Types';
import Plus from '../../assets/Icons/MgmtIcons/Plus.svg';
import openArrow from '../../assets/Icons/LeadIcons/openArrow.svg';
import HolidayFormPopup from './HolidayFormPopup';
import { getLogin } from '../../helpers/authHelper';
import PendingRequests from '../Shared/PendingRequests';
import EmployeeList from '../Shared/EmployeeList';
import { exportHolidayAccrual } from '../../helpers/exports';
import { getSickDaysForCurrentYearMappedToEmployeeId } from '../../helpers/sickDays';
import { getHolidayBalance } from '../../helpers/holidays';

interface MgmtViewProps {
  groups?: string[];
}

const MgmtView = (props: MgmtViewProps) => {
  const { groups } = props;
  const [employees, setEmployees] = useState<User[]>([]);
  const [employeeAbsences, setEmployeeAbsences] = useState<Absence[]>([]);
  const [isAllEmployeesOpen, setIsAllEmployeesOpen] = useState<boolean>(true);
  const [holidayFormOpen, setHolidayFormOpen] = useState(false);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [filterName, setFilterName] = useState('');
  const [sickDaysMap, setSickDaysMap] = useState<{[key: string]: number}>({});
  const [holidayBalanceMap, setHolidayBalanceMap] = useState<{[key: string]: number}>({});
  const [sortByHolidayAccrual, setSortByHolidayAccrual] = useState(false);
  const [sortBySickDays, setSortBySickDays] = useState(false);
  const indirectReports = useMemo(() => [], []);
  const msal = useMsal();

  const handleHolidayFormOpen = () => {
    setHolidayFormOpen(true);
  };

  const handleHolidayFormClose = () => {
    setHolidayFormOpen(false);
  };

  const fetchEmployeeHolidays = useCallback(async () => {
    try {
      const login = await getLogin(msal.instance);

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASEURL}/holidays`, {
        headers: {
          Authorization: `Bearer ${login.idToken}`,
        },
      });

      setHolidays(response.data);
    } catch (error) {
      console.error('Error fetching holidays:', error);
    }
  },[msal.instance]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const login = await getLogin(msal.instance);
  
        const response = await axios.get<User[]>(
          `${process.env.REACT_APP_BACKEND_BASEURL}/users`,
          {
            headers: {
              Authorization: `Bearer ${login.idToken}`,
            },
          }
        );
        if (response.data) {
          const sortedEmployees = response.data.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setEmployees(sortedEmployees);
        }
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };
    const fetchEmployeeAbsences = async () => {
      try {
        const login = await getLogin(msal.instance);
  
        const response = await axios.get<Absence[]>(
          `${process.env.REACT_APP_BACKEND_BASEURL}/absences`,
          {
            headers: {
              Authorization: `Bearer ${login.idToken}`,
            },
          }
        );

        const filteredAbsences = response.data.filter((absence) => absence.deleted === false);
  
        setEmployeeAbsences(filteredAbsences);
      } catch (error) {
        console.error('Error fetching employee absences:', error);
      }
    };

    fetchEmployees();
    fetchEmployeeAbsences();
    fetchEmployeeHolidays();
  }, [msal.instance, fetchEmployeeHolidays]);

  const toggleAllEmployees = () => {
    setIsAllEmployeesOpen(!isAllEmployeesOpen);
  };

  useEffect(() => {
    const getSickDays = async() => {
      if (employeeAbsences.length > 0 && employees.length > 0) {
        const sickDaysMap = await getSickDaysForCurrentYearMappedToEmployeeId(employees, employeeAbsences, msal.instance);
        setSickDaysMap(sickDaysMap);
      }
    };
    getSickDays();
  }, [employees, employeeAbsences, msal.instance]);

  useEffect(() => {
    if (holidays.length > 0 && employees.length > 0) {
      const newMap: {[key: string]: number} = {};
      employees.forEach((employee) => {
        newMap[employee.userId] = getHolidayBalance(employee.userId, holidays);
      });
      setHolidayBalanceMap(newMap);
    }
  },[holidays, employees]);

  return (
    (groups && (groups.includes(process.env.REACT_APP_MANAGEMENT_GROUP as string) ||
    groups.includes(process.env.REACT_APP_HR_GROUP as string))) ?
    <>
      <div className='wrapper'>
      <div className='bar'>
        <div className='search-bar'>
          <input
            type='text'
            placeholder='Search employees...'
            className='search-bar-input'
            value={filterName}
            onChange={(e) => {
              const { value } = e.currentTarget;
              setFilterName(value);
            }}
          />
        </div>

        <div className='header-container'>
          <h1 className='myteam-title'>Management</h1>
          <div className='header-buttons'>
            <button className='request-button' onClick={handleHolidayFormOpen}>
              <img src={Plus} alt='Plus Icon' className='plus-icon' /> Add Holidays
            </button>
          </div>
        </div>
      </div>

      <PendingRequests
        refreshView={() => {
          fetchEmployeeHolidays();
        }}
        holidays={holidays}
        msalInstance={msal.instance}
        users={employees}
        indirectReports={indirectReports}
        sickDaysMap={sickDaysMap}
        holidayBalanceMap={holidayBalanceMap}
        sortByHolidayAccrual={sortByHolidayAccrual}
        setSortByHolidayAccrual={setSortByHolidayAccrual}
        sortBySickDays={sortBySickDays}
        setSortBySickDays={setSortBySickDays}
      />

      <div className='columns-title-container'>
        <div className='column-title' style={{ width: '350px' }}>
          <div className='icon-title' onClick={toggleAllEmployees}>
            <img
              src={openArrow}
              alt='Open Arrow'
              style={{
                width: '22px',
                height: '22px',
                transform: isAllEmployeesOpen ? 'rotate(0deg)' : 'rotate(-90deg)'
              }}
            />
            <h2>All Employees (A-Ö)</h2>
          </div>
        </div>
        <div className='column-title' style={{ width: '305px' }}>
        </div>
        <div className='column-title' style={{ width: '703px' }}>
        </div>
        <div className='column-title' style={{ width: '338px' }}>
        </div>
      </div>

      {isAllEmployeesOpen && <EmployeeList
        absences={employeeAbsences}
        employees={employees.filter((teamMember) => teamMember.name.toLowerCase().includes(filterName.toLowerCase()))}
        sickDaysMap={sickDaysMap}
        holidayBalanceMap={holidayBalanceMap}
        sortByHolidayAccrual={sortByHolidayAccrual}
        sortBySickDays={sortBySickDays}
      />}
      </div>

      {groups !== undefined &&
        (groups.includes(process.env.REACT_APP_MANAGEMENT_GROUP as string) ||
        groups.includes(process.env.REACT_APP_HR_GROUP as string)) ?
      <div className='calendar-export-wrapper'>
        <button
          className='request-button'
          onClick={() => exportHolidayAccrual(holidayBalanceMap, sickDaysMap, employees)}
        >Export</button>
      </div> : null}

      <HolidayFormPopup
        open={holidayFormOpen}
        closePopUp={() => {
          handleHolidayFormClose();
          fetchEmployeeHolidays();
        }}
      />
    </> : null
  );
};

export default MgmtView;